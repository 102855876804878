// theme
@use "@material/theme" with ($primary: #333c8a, $on-primary: white, $error: #cd7865)

// textfield
@use "@material/floating-label/mdc-floating-label"
@use "@material/line-ripple/mdc-line-ripple"
@use "@material/notched-outline/mdc-notched-outline"
@use "@material/textfield"

// button
@use "@material/button"
@use "@material/button/styles" as button-styles

// select
@use "@material/list/mdc-list"
@use "@material/menu-surface/mdc-menu-surface"
@use "@material/menu/mdc-menu"
@use "@material/select"
@use "@material/select/styles" as select-styles

// checkbox
@use "@material/checkbox"

@include textfield.core-styles
@include checkbox.core-styles

$deep-blue: #333c8a
$charcoal: #353f4c
$cadet-gray: #95a4b7
$copper-red: #cd7865
$seashell: #f8ebe8
$medium-gray: #e1e5eb

.preact-text-field
  @include textfield.outline-shape-radius(8px)
  @include textfield.outlined-height(48px)
  @include textfield.fill-color(white)
  @include textfield.outline-color($cadet-gray)
  @include textfield.focused-outline-color($charcoal)
  @include textfield.label-color($cadet-gray)

  &.mdc-text-field--outlined.mdc-text-field--invalid
    @include textfield.fill-color($seashell)
    @include textfield.outline-color($copper-red)
    @include textfield.focused-outline-color($copper-red)
    @include textfield.ink-color($copper-red)
    @include textfield.caret-color($copper-red)
    @include textfield.label-color($copper-red)

  &.mdc-text-field--outlined.mdc-text-field--invalid.mdc-text-field--focused
    @include textfield.focused-outline-color($copper-red)
    @include textfield.ink-color($copper-red)
    @include textfield.label-color($copper-red)

  &.mdc-text-field--invalid .mdc-text-field__input
    font-weight: 600 !important

  &.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid)
    @include textfield.outline-color($cadet-gray)
    @include textfield.ink-color($charcoal)

  &.mdc-text-field--outlined.mdc-text-field--focused
    @include textfield.focused-outline-color($charcoal)
    @include textfield.ink-color($charcoal)
    @include textfield.caret-color($charcoal)
    @include textfield.label-color($cadet-gray)

$select-colors: ("default": $cadet-gray, "hover": $cadet-gray, "focus": $charcoal)

.preact-select
  @include select.outline-shape-radius(8px)
  @include select.outlined-height(48px)
  @include select.container-fill-color(white)
  @include select.outline-color($select-colors)
  @include select.label-color($cadet-gray)
  @include select.label-floating-color($cadet-gray !important)
  @include select.dropdown-icon-color($charcoal)

  &.large
    @include select.outline-shape-radius(10px)
    @include select.outlined-height(60px)
    @include select.outline-color(transparent !important)

  &.error
    @include select.container-fill-color($seashell)
    @include select.outline-color($copper-red)
    @include select.outline-color($copper-red)
    @include select.ink-color($copper-red)
    @include select.label-color($copper-red !important)
    @include select.dropdown-icon-color($copper-red)

    &.mdc-select--focused
      @include select.outline-color($copper-red)
      @include select.ink-color($copper-red)
      @include select.label-color($copper-red)

  &.error .mdc-select__selected-text
    font-weight: 600 !important

  &.large.error .mdc-select__anchor
    box-shadow: none !important

  .mdc-select__anchor
    border-radius: 8px !important

.preact-select.large .mdc-select__anchor
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15) !important
  border-radius: 10px !important
  padding-left: 24px !important

.preact-select .mdc-select__dropdown-icon
  width: 24px
  height: 24px

.preact-select .mdc-select__selected-text
  font-size: 15px

.preact-select.icon .mdc-select__anchor>svg
  align-self: center
  margin-left: -6px
  margin-right: 18px
  min-width: 36px
  color: $charcoal !important

.preact-select.error .mdc-select__anchor>svg
  color: $copper-red !important

.preact-checkbox
  @include checkbox.ripple-size(26px)
  @include checkbox.ink-color(white)
  @include checkbox.container-colors($charcoal, white, $charcoal, $charcoal)
  @include checkbox.focus-indicator-color($deep-blue)
  --mdc-ripple-left: 4px !important
  --mdc-ripple-top: 4px !important

  .mdc-checkbox__background
    width: 14px
    height: 14px
    border-radius: 3px
    border-width: 1px

.preact-checkbox-container
  display: flex

.preact-checkbox + label
  font-family: Lato
  font-size: 11px
  font-weight: normal
  font-stretch: normal
  font-style: normal
  line-height: 1.45
  letter-spacing: normal
  color: $charcoal
  vertical-align: top
  position: relative
  top: 3px
  left: 3px

  a
    color: #04adef !important
